import React from 'react'
import { Link } from "gatsby"
import './index.scss'

import KmLogoSvg from '../../assets/svg/km-logo.svg'
import TelegramSvg from '../../assets/svg/telegram-top-nav.svg';
import TwitterSvg from '../../assets/svg/twitter-top-nav.svg';
import DiscordSvg from '../../assets/svg/discord-top-nav.svg';
import YoutubeSvg from '../../assets/svg/youtube.svg';
import MediumSvg from '../../assets/svg/medium.svg';
import CrossSvg from '../../assets/svg/close-cross.svg';

import MenuHamburgerSvg from '../../assets/svg/menu-hamburger.svg';

import { pageLink } from '../../utils'




const TopNav = ({ 
  showMobileMenu,
  handleMenuButtonClick,
  handleCloseButtonClick
}) => {

  return (
    <>
      <div className="top-nav">
        <nav className="top-nav__top-bar">
          <Link className="desktop-link" to={ pageLink.whitepaper }>WHITEPAPER</Link>
          <Link className="desktop-link" to={ pageLink.token }>TOKEN</Link>
          {/* <Link className="desktop-link" to={ pageLink.mhttp }>MHTTP</Link> */}
          <a className="desktop-link" href={ pageLink.mhttp } target="_blank" rel="noreferrer" aria-label="Discord">MHTTP</a>

          <button 
            className="menu-hamburger" 
            onClick={handleMenuButtonClick}
            aria-label="menu open"
          >
            <MenuHamburgerSvg />
          </button>
        </nav>
        <div className="top-nav__nav-bar">
          <div className="top-nav__nav-bar--left">
            <Link className="no-hover-color" to={ pageLink.home }>
              <KmLogoSvg className="top-nav-logo" />
            </Link>
          </div>
          <div className="top-nav__nav-bar--right">
            <Link className="desktop-link" activeClassName="active" to={ pageLink.developers }>DEVELOPERS</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.community }>COMMUNITY</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.about }>ABOUT</Link>
            
            <a className="icon-link telegram" href={ pageLink.telegram } target="_blank" rel="noreferrer" aria-label="Telegram"><TelegramSvg className="telegram" /></a>
            <a className="icon-link twitter" href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg className="twitter" /></a>
            <a className="icon-link discord" href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg className="discord" /></a>
          </div>
        </div>
      </div>
      <div className={`top-nav--mobile ${showMobileMenu ? "mobile-open" : ""}`}>
        <button 
          className="close-button" 
          onClick={handleCloseButtonClick}
          aria-label="menu close"
        >
          <CrossSvg />
        </button>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.developers } aria-label="developers">DEVELOPERS</a>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.community } aria-label="community">COMMUNITY</a>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.about } aria-label="about">ABOUT</a>
        <a className="top-nav--mobile__link--small-text" href={ pageLink.whitepaper } target="_blank" rel="noreferrer" aria-label="whitepaper">WHITEPAPER</a>
        <a className="top-nav--mobile__link--small-text" href={ pageLink.token } target="_blank" rel="noreferrer" aria-label="token">TOKEN</a>
        <a className="top-nav--mobile__link--small-text" href={ pageLink.mhttp } target="_blank" rel="noreferrer" aria-label="mhttp">MHTTP</a>
        <div className="top-nav--mobile__link-container">
          <a className="icon-link telegram" href={ pageLink.telegram } aria-label="Telegram"><TelegramSvg className="telegram" /></a>
          <a className="icon-link twitter" href={ pageLink.twitter } aria-label="Twitter"><TwitterSvg className="twitter" /></a>
          <a className="icon-link discord" href={ pageLink.discord } aria-label="Discord"><DiscordSvg className="discord" /></a>
          <a className="icon-link youtube" href={ pageLink.youtube } aria-label="youtube"><YoutubeSvg className="youtube" /></a>
          <a className="icon-link medium" href={ pageLink.medium } aria-label="medium"><MediumSvg className="medium" /></a>
        </div>
      </div>
    </>
  )
}

export default TopNav
