export const pageLink = {
  home: "/",
  developers: "/developers",
  community: "/community",
  about: "/about",
  mhttp: "https://mhttp.org/",
  whitepaper: "/",
  token: "/",
  docs: "/",
  github: "/",
  stackoverflow: "/",
  twitter: "https://twitter.com/Keymaker_KEYM",
  discord: "https://discord.gg/keymaker",
  telegram: "https://t.me/keymaker_keym",
  reddit: "https://s.reddit.com/c/182hatuql2ds5",
  medium: "https://medium.com/@Perigee",
  disclaimer: "/",
  tokenomics: "/",
  email: "/",
  youtube: "https://www.youtube.com/channel/UCqDxVBGKU7RfaG9Nut9fQCQ/featured"
}