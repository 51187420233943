import React from 'react'
import { Link } from "gatsby"
import { pageLink } from '../../utils'

import './index.scss'

import TelegramSvg from '../../assets/svg/telegram.svg'
import TwitterSvg from '../../assets/svg/twitter.svg'
import DiscordSvg from '../../assets/svg/discord.svg'
import YoutubeSvg from '../../assets/svg/youtube.svg'
import MediumSvg from '../../assets/svg/medium.svg'


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer-item developers">
          <h3>DEVELOPERS</h3>
          <nav>
            {/* <a href={ pageLink.docs } target="_blank" rel="noreferrer" aria-label="docs">Docs</a>
            <a href={ pageLink.github } target="_blank" rel="noreferrer" aria-label="github">GitHub</a>
            <a href={ pageLink.stackoverflow } target="_blank" rel="noreferrer" aria-label="stackoverflow">StackOverflow</a> */}
            <Link to={ pageLink.docs }>Docs</Link>
            <Link to={ pageLink.github }>GitHub</Link>
            <Link to={ pageLink.stackoverflow }>StackOverflow</Link>
          </nav>
        </div>
        <div className="footer-item community">
          <h3>COMMUNITY</h3>
          <nav className="community">
            <a href={ pageLink.telegram } target="_blank" rel="noreferrer" aria-label="Telegram"><TelegramSvg className="telegram" /></a>
            <a href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg className="twitter" /></a>
            <a href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg className="discord" /></a>
            <a href={ pageLink.youtube } target="_blank" rel="noreferrer" aria-label="Youtube"><YoutubeSvg className="youtube" /></a>
            {/* <a href={ pageLink.medium } target="_blank" rel="noreferrer" aria-label="Medium"><MediumSvg className="medium" /></a> */}
            <Link to={ pageLink.medium }><MediumSvg className="medium" /></Link>
          </nav>
        </div>
        <div className="footer-item about">
          <h3>ABOUT</h3>
          <nav>
            <Link to={ pageLink.about }>Mission</Link>
            <Link to={ pageLink.about }>Whitepaper</Link>
            <Link to={ pageLink.about }>Values</Link>
            <Link to={ pageLink.about }>News</Link>
          </nav>
        </div>
        <div className="footer-item other">
          <h3>OTHER</h3>
          <nav>
            <Link to={ pageLink.tokenomics }>Tokenomics</Link>
            <Link to={ pageLink.disclaimer }>Disclaimer</Link>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
